<template>
  <sign-page
    title-text="医院职称聘任情况(岗位)"
    :request="request"
    :column-list="columnList"
    table-size="large"
    :tableActionsFixed="true"
    :titleMenus="[]"
    @tableAction="tableAction"
    :tableActions="tableActions"
    :need-data-file="true">
    <fm-modal v-model="modal" key="modal" width="80%" v-if="modal" :title="chooseData ? chooseData.type + chooseData.title + '级' : ''">
      <div style="width: 100%;height: 70vh;">
        <fm-table-new
          :columns="columnWorker"
          :auto-height="true"
          border="row"
          :data-list="chooseData && chooseData.workerList ? chooseData.workerList : []"
          :stripe="false"
          size="large"
          emptyPlaceholder="-">
          <table-actions slot="actions" v-if="modal" @table-action="tableAction" slot-scope="{ row }" :data="workerTableActions" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </fm-modal>
    <fm-modal key="modal_p" v-model="modal_p" width="80%" title="个人上岗资格明细">
      <div style="min-height:20rem" v-if="modal_p">
        <div class="worker-card-list" v-if="chooseWorker && chooseWorker.matchDatas && chooseWorker.matchDatas.length > 0">
          <WorkerPromotion :current="chooseWorker.matchDatas[0].workerJobTitle" :worker="chooseWorker.matchDatas[0].worker" :dataList="chooseWorker.matchDatas"></WorkerPromotion>
        </div>
        <div class="worker-card-list-null" v-else>
          <img src="/static/images/null.png" style="width: 40px;margin-bottom:20px;"/>
          <div>暂无数据</div>
        </div>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  jobTitleRequest as request
} from '../../api'

import WorkerPromotion from './worker/workerPromotion'
import TableActions from '@/components/base/TableActions'

export default {
  components: {
    WorkerPromotion,
    TableActions
  },
  methods: {
    search () {
      this.name = this.searchname
    },
    async loadData () {
      let jobTitles = await request.get()
      let dataList = await request.getCurrent()
      let datas = await request.getMatch()
      let allData = []
      datas.forEach(v => {
        allData = allData.concat(v.queue)
      })
      dataList.forEach(v => {
        v.matchDatas = allData.filter(v1 => v1.worker.id === v.workerId && v1.workerJobTitle && v1.workerJobTitle.id === v.id)
      })
      jobTitles.forEach(v => {
        v.workerList = dataList.filter(v1 => v.id === v1.jobTitleId)
        v.workerLength = v.workerList.length
        v.lastNum = v.num != null ? v.num - v.workerLength : null
        if (v.lastNum < 0) {
          v.lastNum = 0
        }
      })
      return jobTitles
    },
    tableAction (parm) {
      if (parm.action === 'detail') {
        this.chooseData = parm.data
        this.modal = true
      } else {
        this.chooseWorker = parm.data
        this.modal_p = true
      }
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    tableActions () {
      return [{
        key: 'detail',
        label: '明细'
      }]
    },
    workerTableActions () {
      return [{
        key: 'worker_detail',
        label: '上岗资格明细'
      }]
    },
    columnWorker () {
      return [{
        title: '员工姓名',
        sort: true,
        fixed: 'left',
        field: 'workerName'
      },
      {
        title: '本岗累积工作年限',
        sort: true,
        dataType: Number,
        field: 'diffYear'
      },
      {
        title: '聘任起始时间',
        sort: true,
        field: 'hireStartDate',
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
        }
      },
      {
        title: '聘任终止时间',
        sort: true,
        field: 'hireEndDate',
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '至今')
        }
      },
      {
        title: '参加工作时间',
        sort: true,
        field: 'workerCorrectionDate',
        render: (h, rowData) => {
          return h('div', rowData.workerCorrectionDate ? rowData.workerCorrectionDate.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.workerCorrectionDate ? rowData.workerCorrectionDate.slice(0, 10) : ''
        }
      },
      {
        title: '入职单位时间',
        sort: true,
        width: '40',
        field: 'workerEntryDate',
        render: (h, rowData) => {
          return h('div', rowData.workerEntryDate ? rowData.workerEntryDate.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.workerEntryDate ? rowData.workerEntryDate.slice(0, 10) : ''
        }
      },
      {
        title: '操作',
        width: 120,
        field: 'actions',
        slot: 'actions',
        fixed: 'right',
        search: false,
        export: false
      }]
    },
    columnList: {
      get () {
        return [{
          title: '岗位类型',
          sort: true,
          field: 'type',
          filters: this.$store.getters.selectDatas['job_title_type'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位层级',
          sort: true,
          field: 'topLevel',
          filters: [{key: '初级', label: '初级'}, {key: '中级', label: '中级'}, {key: '高级', label: '高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '职称级别',
          sort: true,
          field: 'mLevel',
          filters: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '岗位等级',
          sort: true,
          field: 'title',
          filterMethod (parm) {
            return parm.row.title === parm.value
          }
        },
        // {
        //   title: '需要资格证书',
        //   field: 'needCertificateTitle'
        // },
        // {
        //   title: '需要职称',
        //   field: 'needJobTitleTitle'
        // },
        // {
        //   title: '需要职称年限',
        //   field: 'needYear'
        // },
        {
          title: '实际可用岗位数',
          sort: true,
          field: 'num',
          filterRange: true,
          dataType: Number
        },
        {
          title: '岗位实际人数',
          filterRange: true,
          sort: true,
          field: 'workerLength',
          dataType: Number
        },
        {
          title: '岗位空置数',
          sort: true,
          dataType: Number,
          filterRange: true,
          field: 'lastNum',
          render: (h, rowData) => {
            return h('div', isNaN(rowData.lastNum) ? '-' : (rowData.lastNum < 0 ? 0 : rowData.lastNum))
          }
        }]
      }
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      modal_p: false,
      chooseWorker: null,
      modal: false,
      chooseData: null
    }
  }
}
</script>

<style lang="less" scoped>
.worker-card-list {
  display: flex;
  width: 100%;
  overflow-x:auto;
  .worker-card-item {
    margin-right: 1rem;
  }
}
.modal-s {
  position: absolute;
  top: 6px;
  right: 60px;
  display: flex;
  justify-content: flex-end;
}
.worker-card-list-null {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
